import request from '@/utils/request';

export function save(data) {
  return request({
    url: 'v1/androidVersion/save',
    method: 'post',
    data,
  });
}

export function fetch(versionNumber) {
  return request({
    url: `v1/androidVersion/${versionNumber}`,
    method: 'get',
  });
}

export function fetchList() {
  return request({
    url: 'v1/androidVersion/list',
    method: 'get',
  });
}
