<template>
  <el-card class="form-container" shadow="never">
    <el-form :model="version">
      <el-form-item label="Version Number" prop="versionNumber">
        <el-input v-model="version.versionNumber"></el-input>
      </el-form-item>
      <el-form-item label="Version Name" prop="versionName">
        <el-input v-model="version.versionName"></el-input>
      </el-form-item>
      <el-form-item label="Caption" prop="caption">
        <el-input v-model="version.caption"></el-input>
      </el-form-item>
      <el-form-item label="Body1" prop="body1">
        <el-input v-model="version.body1"></el-input>
      </el-form-item>
      <el-form-item label="Body2" prop="body2">
        <el-input v-model="version.body2"></el-input>
      </el-form-item>
      <el-form-item label="Body3" prop="body3">
        <el-input v-model="version.body3"></el-input>
      </el-form-item>
      <el-form-item label="Illustration Image" prop="illustrationImg">
        <single-upload v-model="version.illustrationImg"></single-upload>
      </el-form-item>
      <el-form-item label="Force Update" prop="forceUpdate">
        <el-checkbox v-model="version.forceUpdate"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">Submit</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import SingleUpload from '@/components/Upload/singleUpload';
import { save, fetch } from '@/api/androidVersion';
import { setPopupLowerVersion } from '@/api/consumer';

const defaultVersion = {
  versionNumber: null,
  versionName: null,
  caption: null,
  body1: null,
  body2: null,
  body3: null,
  illustrationImg: null,
  forceUpdate: false,
};
export default {
  name: 'AndroidVersionForm',
  components: { SingleUpload },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.isEdit) {
      fetch(this.$route.query.versionNumber).then((response) => {
        this.version = response.data;
      });
    }
  },
  data() {
    return {
      version: { ...defaultVersion },
    };
  },
  methods: {
    submit() {
      this.$confirm('是否提交数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        save(this.version).then(() => {
          setPopupLowerVersion({ value: true }).then(() => {
            this.$message({
              message: 'Success',
              type: 'success',
              duration: 1000,
            });
            setTimeout(() => {
              location.reload();
            }, 1000);
          });
        });
      });
    },
  },
};
</script>
