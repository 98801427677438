import request from '@/utils/request';

export function fetchAll() {
  return request({
    url: 'v1/consumer/listAll',
    method: 'get',
  });
}

export function fetchActive() {
  return request({
    url: 'v1/consumer/listActive',
    method: 'get',
  });
}

export function fetchAdmin() {
  return request({
    url: 'v1/consumer/listAdmin',
    method: 'get',
  });
}

export function disableById(id) {
  return request({
    url: `v1/consumer/disable/${id}`,
    method: 'patch',
  });
}

export function createCaptain(id, data) {
  return request({
    url: `v1/consumer/createCaptain/${id}`,
    method: 'post',
    data,
  });
}

export function getToken(params) {
  return request({
    url: 'v1/consumer/getToken',
    method: 'get',
    params,
  });
}

export function list(params) {
  return request({
    url: 'v1/consumer/list',
    method: 'get',
    params,
  });
}

export function setPopupLowerVersion(data) {
  return request({
    url: 'v1/consumer/setPopupLowerVersion',
    method: 'post',
    data,
  });
}

export function getPromoAmount(params) {
  return request({
    url: 'v1/consumer/getPromoAmount',
    method: 'get',
    params,
  });
}

export function setPromoAmount(data) {
  return request({
    url: 'v1/consumer/setPromoAmount',
    method: 'post',
    data,
  });
}
