<template>
  <version-form :is-edit="true"></version-form>
</template>
<script>
import VersionForm from './VersionForm';

export default {
  name: 'EditVersion',
  components: { VersionForm },
};
</script>
